<template>
  <div>
    <!-- <pre>{{ po[0] }}</pre>  -->
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">SUPPLIER bthrust company</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <v-row class="">
                <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Supplier Detail</h6>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                    <span style="text-transform: uppercase">{{
                      po[0].supplier?.company_name
                    }}</span>
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                    <span v-if="po[0].supplier?.company_email">{{
                      po[0].supplier?.company_email
                    }}</span>
                    <span v-else>NO Email</span>
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                    <span v-if="po[0].supplier?.company_phone">{{
                      po[0].supplier?.company_phone
                    }}</span>
                    <span v-else>No Phone Number</span>
                  </div>
                </v-col>
                <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Contact Person</h6>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                    <span style="text-transform: uppercase">{{ po[0].contact?.display_name }}</span>
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                    <span v-if="po[0].contact?.primary_email" style="text-transform: uppercase">
                      {{ po[0].contact?.primary_email }}</span
                    >
                    <span v-else>No Email</span>
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                    <span v-if="po[0].contact?.primary_phone" style="text-transform: uppercase">{{
                      po[0].contact?.primary_phone
                    }}</span>
                    <span v-else>No Phone Number</span>
                  </div>
                </v-col>
                <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Address</h6>
                  <div>
                    <div class="d-flex">
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-map</v-icon>
                      </div>
                      <span
                        >{{ po[0].supplier?.country }}<br />
                        {{ po[0].supplier.address_line_1 }}<br />
                        {{ po[0].supplier.address_line_2 }}<br />
                        {{ po[0].supplier.country }}<br />
                        {{ po[0].supplier.postal_code }}</span
                      >
                    </div>
                  </div>
                </v-col>
                <v-col md="3"> </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col md="12" class="px-0 pt-5 pb-0">
        <v-expansion-panels elevation="1" v-model="panels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">OTHER DETAILS</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <v-row class="">
                <table width="100%">
                  <tr>
                    <td width="320" class="py-1"><span class="font-weight-bold">Remark</span></td>
                    <td class="py-1">
                      <span class="">{{ po[0].remarks }}</span>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="py-1"><span class="font-weight-bold">Terms & Conditions</span></td>
                    <td class="py-1">
                      <span class=""
                        >deliverable or other product or result from Services that is referred to in
                        a Purchase Order.</span
                      >
                    </td>
                  </tr> -->
                </table>
                <!-- <v-col md="3">
                    <span class="font-weight-bold">Remark</span>
                </v-col>
                <v-col md="6">
                    <span class="">Remark</span>
                </v-col>
                <v-col md="3">
                    <span class="font-weight-bold">Terms & Conditions</span>
                </v-col>
                <v-col md="6">
                    <span class="">deliverable or other product or result from Services that is referred to in a Purchase Order.</span>
                </v-col> -->
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "purchase-order-detail",
  data() {
    return {
      panel: [0],
      panels: [0],
      // product: {
      // }
      purchaseOrder: [{}],
    };
  },
  props: {
    po: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.product-row {
  margin: 0 !important;
}
</style>

<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- {{ bill.added_by.display_name }}
   -->

      <!-- {{ searchIteam }} -->

      <v-col md="12" class="header-title py-0"></v-col>
      <v-col md="6" class="my--" style="background-color: #f0f8ff">
        <div class="d-flex align-center">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">{{ bill.bill_number }}</h1>
          <!-- <v-chip small color="success">Paid</v-chip> -->
          <!-- <v-btn v-if="bill.status != 'Paid'"
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="blue darken-4"
            v-on:click="recordPaymentDialog = true"
          >
            Record Payment
          </v-btn> -->
          <!-- <v-chip small color="success">{{ bill.status }}</v-chip> -->
          <v-chip
            v-if="bill.status == 'Pending'"
            small
            outlined
            label
            :color="bill.amountStatus == 'Completed' ? 'success' : 'orange'"
            >Final Pending</v-chip
          >
          <v-chip
            v-else
            small
            outlined
            label
            :color="bill.amountStatus == 'Completed' ? 'success' : 'green'"
            >Final Paid</v-chip
          >

          <v-btn
            v-if="bill?.receiving_add"
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="success"
          >
            {{ bill?.receiving_add?.value }}
          </v-btn>
        </div>
      </v-col>
      <v-col md="6" class="text-right" style="background-color: #f0f8ff">
        <div class="d-flex align-center justify-content-end">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">Total:</h1>
          <h1 class="blue--text font-weight-bold mb-0">{{ formatMoney(getTotal()) }}</h1>
          <!-- <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="blue darken-4"
            :to="{
              name: 'bills-update',
              params: { id: itemId },
              query: { t: new Date().getTime() },
            }"
          >
            Edit
          </v-btn> -->
          <!-- <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="red lighten-1"
            v-on:click="deleteConfirm()"
          >
            Delete
          </v-btn> -->
          <v-btn
            v-if="bill.status != 'Paid'"
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="blue darken-4"
            v-on:click="recordPaymentDialog = true"
          >
            Record Payment
          </v-btn>
          <v-btn
            :disabled="pageLoading"
            depressed
            v-on:click="downloadPdf()"
            tile
            class="mx-2 white--text"
            color="red darken-4"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile @click="routeTo()">
            Back
          </v-btn>
        </div>
      </v-col>
      <v-col md="12" class="py-0" style="background-color: #f0f8ff">
        <v-row>
          <v-col md="4" class="pb-2 pt-0">
            <b>PV :</b>
            <span v-if="bill?.record?.barcode" class="text-h6 font-weight-bold"
              >{{ bill?.record?.code }}{{ bill?.record?.barcode }}</span
            >
            <div class="pb-2" v-if="!bill?.receiving_add">
              <b>Payment To :</b>
              <span class="text-h6 font-weight-bold">{{ bill?.supplier?.company_name }}</span>
            </div>
            <div class="pb-2" v-else>
              <b>Payment To :</b>
              <span class="text-h6 font-weight-bold">{{ bill?.receiving_add?.value }}</span>
            </div>
            <div><b>Due Date :</b>{{ formatedateTimes(bill.due_date) }}</div>
          </v-col>

          <v-col md="4" class="pb-2 pt-0">
            <div class="pb-2">
              <b
                >Ref Number
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="disabled" size="18"
                      >mdi-help-circle-outline</v-icon
                    >
                  </template>
                  <span>Reference Number Printed on pdf</span>
                </v-tooltip>
                :</b
              >
              {{ bill.ref_number }}
            </div>
            <div><b>Receiving Warehouse : </b><span>Sutra</span></div>
          </v-col>
          <v-col md="4" class="pb-2 pt-0">
            <div class="pb-2"><b>Created by :</b> {{ bill?.added_by?.display_name }}</div>
            <div><b>Created Date :</b>{{ formatedateTime(bill.added_at) }}</div>
          </v-col>
        </v-row>
      </v-col>
      <!-- left side -->
      <v-col v-if="false" md="3" class="pr-2">
        <v-card elevation="2" style="height: 100%">
          <v-card-item class="d-flex justify-start pt-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2"
                  style="cursor: pointer"
                  @click="goBack()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="me-2 white--text" v-bind="attrs" v-on="on" small color="green"
                  >Received</v-chip
                >
              </template>
              <span>Received Status</span>
            </v-tooltip>
          </v-card-item>

          <v-card-item class="d-flex justify-center pt-3">
            <ImageTemplate
              src="https://upload.wikimedia.org/wikipedia/commons/4/47/Hamburger_%28black_bg%29.jpg"
              :maxWidth="150"
              :maxHeight="150"
              :aspectRatio="1"
              :contain="false"
              circle
            />
          </v-card-item>

          <v-card-item class="d-flex justify-center align-center">
            <v-card-title class="font-weight-bold">bthrust pvt ltd</v-card-title>
          </v-card-item>

          <v-card-item class="d-flex align-center justify-center pb-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="blue darken-4"
                  :to="{
                    name: 'purchase-order-update',
                    params: { id: customerId },
                    query: { t: new Date().getTime() },
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="red lighten-1"
                  v-on:click="deleteCustomerConfirm()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-item>
          <v-divider class="mt-0"></v-divider>
          <v-card-item class="d-flex flex-column align-center" style="overflow-y: auto">
            <v-row class="basic-detail-row" style="width: 100%">
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Barcode</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">#PO0001</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Ref Number</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">101</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Delivery Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>{{ formatedateTime(bill.added_at) }}
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
            </v-row>
            <div class="py-5">
              <v-btn
                :disabled="pageLoading"
                depressed
                tile
                class="mx-2 white--text"
                color="blue darken-4"
                :to="{
                  name: 'bills-update',
                  params: { id: itemId },
                  query: { t: new Date().getTime() },
                }"
              >
                Edit
              </v-btn>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col class="pt-0 pe-0" style="width: calc(100% - 320px); border-right: 1px solid #d8dbdd">
        <v-tabs
          fixed-tabs
          v-model="purchaseOrderTab"
          background-color="transparent"
          color="blue darken-4"
          class="customtabs"
        >
          <v-tab
            style="width: 100%"
            v-for="(tab, index) in purchase_order_tabs"
            :key="`product_${tab.key}_${index}`"
            :href="`#${tab.key}`"
            icons-and-text
          >
            <v-icon :size="18" class="me-1">{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider class="mt-0"></v-divider>
        <!-- <v-card>
            <v-card-item> -->

        <v-tabs-items v-model="purchaseOrderTab" style="height: 65vh; overflow-y: auto">
          <template v-for="(tab, index) in purchase_order_tabs">
            <v-tab-item :value="tab.key" :key="tab.key + '_' + index">
              <component
                :selectedBillData="selectedBillData"
                :is="tab.template"
                :po="data"
                :log="bill"
                :key="rerenderKey"
              />
            </v-tab-item>
          </template>
        </v-tabs-items>
        <!-- </v-card-item>
          </v-card> -->
      </v-col>
      <v-col style="max-width: 320px" class="ps-0 pt-0">
        <v-card elevation="1" height="100%">
          <v-list dense class="py-0 text-end">
            <div class="px-3 pt-0 pb-3 d-flex align-center">
              <div class="my-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="supplier"
                  v-on:keyup="searchingIteam"
                  placeholder="Search..."
                  v-model="searchSupplier"
                  prepend-inner-icon="mdi-magnify"
                >
                </TextInput>
              </div>
              <v-menu
                offset-y
                left
                transition="slide-y-transition"
                :close-on-content-click="false"
                v-model="showFilter"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="blue darken-4"
                        depressed
                        @click="showFilter = !showFilter"
                        tile
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        class="white--text ms-2 mt-3"
                        height="34"
                      >
                        <v-icon>{{ showFilter ? "mdi-filter-remove" : "mdi-filter-plus" }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter</span>
                  </v-tooltip>
                </template>
                <v-list nav dense>
                  <v-list-item class="flex-column">
                    <v-form
                      ref="productAddForm"
                      v-model.trim="formValid"
                      lazy-validation
                      v-on:submit.stop.prevent="updateOrCreate"
                    >
                      <div class="pb-2">
                        <AutoCompleteInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items.sync="paymentTypeList"
                          id="payment-type"
                          outlined
                          v-model="paymentType"
                          placeholder="Payment type"
                          v-on:click:clear="getItem()"
                        >
                        </AutoCompleteInput>
                      </div>
                      <div class="pb-2">
                        <DatePicker
                          hide-details
                          clearable
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="delivery-date"
                          v-model="delivery_date"
                          placeholder="Delivery Date"
                          v-on:click:clear="getItem()"
                        ></DatePicker>
                      </div>
                      <div class="text-right">
                        <!-- <v-btn :disabled="pageLoading" depressed tile class="" :close-on-content-click="false">
                            Close
                          </v-btn> -->
                        <v-btn
                          color="blue darken-4"
                          depressed
                          tile
                          class="white--text ms-2"
                          @click="fill"
                        >
                          Filter
                        </v-btn>
                      </div>
                    </v-form>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!-- {{ itemss }} -->

            <v-divider class="my-0"></v-divider>

            <v-list-item-group class="puchase-listing" color="primary" v-if="itemss?.data?.length">
              <v-list-item
                class="puchase-listing-item cursor-pointer px-2"
                v-for="(item, i) in itemss.data"
                :key="i"
                @click="setCurrent(item.uuid)"
                :class="{ active: item.uuid === currentElement }"
                style="border-bottom: 1px solid darkgrey"
              >
                <!-- <v-checkbox class="mx-0" v-model="checked"
                 ></v-checkbox> -->
                <!-- <v-checkbox
                  @click="addPoBillsData(item.id)"
                  class="mx-0"
                  :disabled="myArray.includes(item.uuid)" 
                  multiple
                  v-model="item.checkbox"
                  :value="item.check"
                ></v-checkbox> -->

                <v-checkbox
                  class="mx-0"
                  v-model="checked"
                  :value="item.uuid"
                  :disabled="
                    myArray.includes(item.uuid) ||
                    item.type == 'bill' ||
                    (item.bill_no ? true : false)
                  "
                  multiple
                  v-on:click="() => getPayments(checked)"
                ></v-checkbox>
                <v-list-item-content
                  v-if="item.type == 'bill'"
                  link
                  v-on:click="routeToDetail(item.uuid)"
                >
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p
                        v-if="!item.receiving_add"
                        class="mb-2 font-weight-bold blue--text text-start"
                      >
                        {{ item.supplier_company }}
                      </p>
                      <p v-else class="mb-2 font-weight-bold blue--text text-start">
                        {{ item.receiving_add }}
                      </p>
                      <div class="d-flex black--text">
                        <p class="mb-0 border-right me-1">{{ item.barcode }}</p>
                        <span
                          ><v-icon size="15">mdi-calendar</v-icon
                          >{{ formatedateTimes(item?.date) }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 font-weight-bold black--text">{{ formatMoney(item.total) }}</p>
                      <div v-if="item.type == 'bill'">
                        <v-chip
                          class="px-1 white--text"
                          v-if="item.status == 'Pending'"
                          small
                          :color="item.amountStatus == 'Completed' ? 'success' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          small
                          class="px-1 white--text"
                          v-else-if="item.status == 'Paid'"
                          :color="item.amountStatus == 'Completed' ? 'success' : 'green'"
                          >Final Paid</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-chip
                          v-if="!item.bill_no && item.status == 'Pending'"
                          small
                          class="px-1"
                          outlined
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'red'"
                          >Initial Pending</v-chip
                        >
                        <v-chip
                          v-else-if="!item.bill_no && item.status == 'Initial Paid'"
                          small
                          class="px-1"
                          outlined
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'indigo'"
                          >Initial Paid</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Initial Paid'"
                          small
                          class="px-1"
                          outlined
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'indigo'"
                          >Initial Paid</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Pending'"
                          small
                          class="px-1"
                          outlined
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Paid'"
                          small
                          class="px-1"
                          outlined
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'green'"
                          >Final Paid</v-chip
                        >
                      </div>
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p
                        v-if="!item.receiving_add"
                        class="mb-2 font-weight-bold blue--text text-start"
                      >
                        {{ item.supplier_company }}
                      </p>
                      <p v-else class="mb-2 font-weight-bold blue--text text-start">
                        {{ item.receiving_add }}
                      </p>
                      <div class="d-flex black--text">
                        <p class="mb-0 border-right me-1">{{ item.barcode }}</p>
                        <span
                          ><v-icon size="15">mdi-calendar</v-icon
                          >{{ formatedateTimes(item?.date) }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 font-weight-bold black--text">{{ formatMoney(item.total) }}</p>
                      <div v-if="item.type == 'bill'">
                        <v-chip
                          class="px-1 white--text"
                          v-if="item.status == 'Pending'"
                          small
                          :color="item.amountStatus == 'Completed' ? 'success' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          small
                          class="px-1 white--text"
                          v-else-if="item.status == 'Paid'"
                          :color="item.amountStatus == 'Completed' ? 'success' : 'green'"
                          >Final Paid</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-chip
                          v-if="!item.bill_no && item.status == 'Pending'"
                          small
                          class="px-1 white--text"
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'red'"
                          >Initial Pending</v-chip
                        >
                        <v-chip
                          v-else-if="!item.bill_no && item.status == 'Initial Paid'"
                          small
                          class="px-1 white--text"
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'indigo'"
                          >Initial Paid</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Initial Paid'"
                          small
                          class="px-1 white--text"
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'indigo'"
                          >Initial Paid</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Pending'"
                          small
                          class="px-1 white--text"
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          v-else-if="item.bill_no && item.status == 'Paid'"
                          small
                          class="px-1 white--text"
                          label
                          :color="item.amountStatus == 'Completed' ? 'success' : 'green'"
                          >Final Paid</v-chip
                        >
                      </div>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <template v-else>
              <tr>
                <td colspan="9">
                  <p class="m-0 text-center">
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mr-4"
                    />
                    Uhh... There are no data at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <DeleteTemplate
      type="item"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <RecordPaymentDialog
      :dialog="recordPaymentDialog"
      :bills="bill"
      :total="data"
      @close="recordPaymentDialog = false"
    >
    </RecordPaymentDialog>
  </v-sheet>
</template>

<script>
import { toSafeInteger, find, startsWith } from "lodash";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FIND_BILL, FIND_BILL_DETAIL } from "@/core/lib/bill.lib";
import { FIND_POCHECK_BILL } from "@/core/lib/pos.lib";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import FileUpload from "@/view/components/FileUpload";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectInput from "@/view/components/SelectInput";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import PurchaseOrderBillsDetails from "./PurchaseOrderBillsDetails";
import POLineItemsTab from "./POLineItemsTab";
import AttactmentTab from "./AttactmentTab";
import LogTab from "./LogTab";
import RecordPaymentDialog from "./RecordPaymentDialog";
import PosMixin from "@/core/mixins/pos.mixin";
import moment from "moment-timezone";

import { PATCH } from "@/core/services/store/request.module";

export default {
  mixins: [PosMixin],
  name: "item-detail",
  title: "Detail Item",

  data() {
    return {
      rerenderKey: Number(new Date()),

      currentElement: 0,
      excelLoading: null,
      itemss: [],
      bill: [],
      checked: [],
      purchase: [],
      myArray: [],
      data: [],
      pageLoading: false,
      deleteText: null,
      formValid: false,
      deleteEndpoint: null,
      deleteDialog: false,
      recordPaymentDialog: false,
      images: [],
      itemId: null,
      selectedItem: 0,
      //checkbox: false,
      showFilter: false,
      delivery_date: "",
      searchSupplier: "",
      paymentType: null,

      paymentRecordData: {
        payment_made: "4,750",
        payment_number: "2",
        payment_date: "",
        payment_mode: "Cash",
        payment_through: "Petty Cash",
        reference: "",
        payment_notes: "",
        attachments: [],
        notification_checkbox: false,
        sendemail1: true,
        sendemail2: false,
      },
      paymentTypeList: [
        {
          text: "Final Paid",
          value: "Paid",
          color: "",
        },
        {
          text: "Final Pending",
          value: "final_pending",
          color: "",
        },
        {
          text: "Initial Pending",
          value: "pending",
          color: "",
        },
        {
          text: "Initial Paid",
          value: "Initial Paid",
          color: "",
        },
      ],
      supplierFilter: [
        {
          title: "Actions",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "#",
          status: true,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Details",
          status: false,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "blue darken-4",
          },
        },
        {
          title: "Supplier",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Contact Person",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
      ],
      items: [
        {
          id: 1,
          supplierName: "bthrust company",
          po_number: "PO0001",
          delivery_date: "08/09/2023",
          amount: "4,750.00",
          checkbox: true,
        },
        {
          id: 2,
          supplierName: "bthrust compan",
          po_number: "PO0002",
          delivery_date: "08/09/2023",
          amount: "4,750.00",
          checkbox: false,
        },
        {
          id: 3,
          supplierName: "Supplier name",
          po_number: "PO0003",
          delivery_date: "08/09/2023",
          amount: "4,750.00",
          checkbox: false,
        },
      ],
      purchaseOrderTab: null,
      purchase_order_tabs: [
        {
          key: "line-items",
          title: "LIne Items",
          icon: "mdi-view-list",
          template: POLineItemsTab,
          props: "selectedBillData",
        },
        {
          key: "details",
          title: "Details",
          icon: "mdi-information-outline",
          template: PurchaseOrderBillsDetails,
        },

        // {
        //   key: "pending",
        //   title: "Pending",
        //   icon: "mdi-view-list",
        //   template: PendingDetailTab,
        // },
        {
          key: "attactments",
          title: "Attachments",
          icon: "mdi-file-multiple",
          template: AttactmentTab,
        },
        {
          key: "logs",
          title: "Logs",
          icon: "mdi-history",
          template: LogTab,
        },
      ],
      item: {
        po_number: null,
        name_en: null,
        name_ch: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
      purchase_order_details: [
        {
          name: "Code",
          value: "BT002",
        },
        {
          name: "Category",
          value: "Disposable",
        },
        {
          name: "Department Category",
          value: "Bar Alcoholic",
        },
        {
          name: "Primary (sales) UOM",
          value: "BTL (BTL)",
        },
        {
          name: "Secondary (purchase) UOM",
          value: "CTN (CTN)",
        },
        {
          name: "Unit Description (primary sales uom)",
          value: "250ml",
        },
      ],
      purchaseOrder: [
        {
          id: 1,
          po_number: "PO0001",
          supplier: "bthrust pvt ltd",
          refNumber: "101",
          delivery_date: "08/09/2023",
          project: "",
          amountValue: "4,750.00",
          amountStatus: "Paid",
          po_status: "Draft",
          paymentModes: "Cash",
          payment_voucher: "ABCD123",
        },
      ],
      selectedBillData: [],
      rowuct: {
        name: "Pineapple juice",
      },
    };
  },
  methods: {
    routeTo() {
      this.$router.push({
        name: "bills-listing",

        query: { t: new Date().getTime() },
      });
    },
    downloadPdf() {
      this.pdf = this.$route.params.id;
      console.log(this.pdf, "pdf");
      let downloadURL = process.env.VUE_APP_API_URL + `bill-pdf/${this.pdf}`;
      console.log(downloadURL, "downloadURL");
      downloadURL = new URL(downloadURL);
      window.open(downloadURL, "_blank");
    },
    routeToDetail(row) {
      console.log(row);

      this.$router.push({
        name: "bills-detail",
        params: { id: row },
        query: { t: new Date().getTime() },
      });
      this.getItem();

      this.getbill();
    },

    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    setCurrent(i) {
      this.currentElement = i;
    },
    searchingIteam() {
      let data = this.searchIteam.filter((item) => {
        return (
          startsWith(item.supplier_company.toLowerCase(), this.searchSupplier.toLowerCase(), 0) ||
          startsWith(item.receiving_add.toLowerCase(), this.searchSupplier.toLowerCase(), 0) ||
          startsWith(item.barcode.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
        );
      });
      console.log(data, "dd");
      this.itemss.data = data;
    },
    //     searchingIteam() {
    //       // let data = this.searchIteam.filter((item) =>
    //       //   startsWith(item.supplier.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
    //       // );
    //       let data = this.searchIteam.filter((item) => {
    //        return startsWith(item.supplier_company.toLowerCase(), this.searchSupplier.toLowerCase(), 0) || startsWith(item.receiving_add.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
    //  });
    //       this.itemss = data;
    //       console.log(data,'data')
    //     },
    fill() {
      this.date = this.delivery_date;
      this.status = this.paymentType;

      console.log(this.date, "fdf");
      this.$store
        .dispatch(PATCH, {
          url: `bills-filter`,
          data: {
            bill_date: this.date,
            status: this.status,
            id: this.$route.params.id,
          },
        })
        .then((data) => {
          this.itemss.data = data.data;
          this.showFilter = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getTotal() {
      let sum = 0;

      this.data?.forEach((element) => {
        sum = sum + Number(element.total);
      });
      console.log(sum);
      this.totalss = Number(sum);
      //  this.getDiscount();
      console.log(this.totalss, "total");

      return this.totalss;
    },
    async getPayments(item) {
      this.purchase = item;
      const pos = await FIND_POCHECK_BILL(this.purchase);
      this.data = pos;
      // this.bulk = pos;
      // this.single = {}

      console.log(this.data, "poss");
    },
    async getPayment(id) {
      try {
        const data = await FIND_BILL_DETAIL(id);
        this.bill = data;
        //  const myArray = text.split(" ");
        const myArrays = this.bill.purchase_order.split(",");
        this.myArray = myArrays;
        this.checked = this.myArray;
        this.getPayments(this.myArray);
        console.log(this.myArray, "kkk");

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    addPoBillsData(id) {
      const itemToAdd = this.items.find((item) => item.id === id);
      if (!this.selectedBillData.some((item) => item.id === id)) {
        this.selectedBillData.push(itemToAdd);
        console.log("data", itemToAdd);
      }
    },
    // addPoBillsData(item) {
    //   console.log('data',item);
    //   item.checkbox = true;
    //   let filteredData = this.selectedBillData.filter((row) => {
    //     // return row.id === item.id;
    //     if (row.id == item.id) return row;
    //     console.log('row',row)
    //   });
    //   if (filteredData.length === 0) {
    //     this.selectedBillData.push(item);
    //     console.log('filteredData', filteredData);
    //   }
    // },

    deleteConfirm() {
      this.deleteText = this.item.po_number;
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      if (this.item.name_en) {
        return this.item.name_en;
      }
      return "Purchase Order";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },

    async getItem() {
      try {
        const item = await FIND_BILL(this.$route.params.id);

        this.itemss = item;
        (this.searchIteam = item.data), (this.currentElement = this.$route.params.id);

        console.log(this.itemss, "kkk");

        this.images = item.images;

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.po_number, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.showFilter = false;
      }
    },
    async getbill() {
      try {
        const data = await FIND_BILL_DETAIL(this.$route.params.id);
        this.bill = data;
        //  const myArray = text.split(" ");
        const myArrays = this.bill.purchase_order.split(",");
        this.myArray = myArrays;
        this.checked = this.myArray;
        this.getPayments(this.myArray);
        console.log(this.myArray, "kkk");

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
        ]);
        this.rerenderKey = Number(new Date());
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  // computed: {
  //   purchaseOrderTab: {
  //     set(val) {
  //       let query = { ...this.$route.query };
  //       query.tab = val;
  //       query.t = new Date().getTime();
  //       if (val != this.purchaseOrderTab) {
  //         this.$router.replace({ query });
  //       }
  //     },
  //     get() {
  //       return this.$route.query.tab || "line-items";
  //     },
  //   },
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.getItem();
      this.getbill();
    } else {
      this.goBack();
    }
  },

  components: {
    DeleteTemplate,
    ImageTemplate,
    ValueTemplate,
    PurchaseOrderBillsDetails,
    POLineItemsTab,
    AttactmentTab,
    LogTab,
    DatePicker,
    TextInput,
    TextAreaInput,
    SelectInput,
    Dialog,
    FileUpload,
    RecordPaymentDialog,
    AutoCompleteInput,
  },
  computed: {},
};
</script>
<style scoped>
.header-title {
  position: sticky;
  width: 85%;
  background-color: white;
  z-index: 99;
  top: 65px;
  border-top: 8px solid #f7941e !important;
}

.customtabs .v-tabs .v-tab:not(.v-tab--active) {
  background-color: #fff !important;
}

.customtabs.v-tabs .v-slide-group__wrapper .v-tab {
  font-size: 14px !important;
  font-weight: 600 !important;
}

/* .po-line-items tbody tr:nth-child(even) {
    background-color: #e3eff9 !important;
  } */
.puchase-listing .puchase-listing-item:nth-child(odd) {
  background-color: #f2f6fa !important;
}

.puchase-listing .puchase-listing-item.active.cursor-pointer.v-list-item.theme--light {
  background-color: #9fcbf3 !important;
  /* opacity: 0.3!important; */
}

.puchase-listing-item .theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}
</style>
